
import { computed, defineComponent } from "vue";
// import HelloWorld from "./components/HelloWorld.vue";
import Header from "./components/Header.vue";
import Connect from "./components/Connect.vue";

export default defineComponent({
  name: "App",
  components: {
    // HelloWorld,
    Header,
    Connect,
  },
});
